<template lang="html">
  <div class="audio-player" v-if="file && ext">
    <audio
      ref="audio"
      :src="file"
      @timeupdate="onTimeUpdate"
      @loadedmetadata="loadAudio"
    >
      <source :src="file" type="audio/mpeg" />
    </audio>
    <div class="controls">
      <div class="time">{{ displayTime }}</div>
      <input
        class="range"
        type="range"
        min="0"
        :max="duration"
        step="any"
        value="0"
        v-model="time"
        @change="changeTime"
      />
      <div class="playpause" @click="playpause">
        <span v-if="playing">Pause</span>
        <span v-else>Play</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: String,
    ext: String,
  },
  data() {
    return {
      playing: false,
      displayTime: "00:00",
      time: 0,
      duration: 0,
    };
  },
  mounted() {
    this.time = 0;
    this.$root.$on("stop-playing", this.stop);
  },
  methods: {
    loadAudio() {
      this.time = 0;
      this.duration = this.$refs.audio.duration;
      this.displayTime =
        this.timeParse(this.$refs.audio.currentTime) +
        " / " +
        this.timeParse(this.$refs.audio.duration);
    },
    onTimeUpdate() {
      this.time = this.$refs.audio.currentTime;
    },
    changeTime(event) {
      this.$refs.audio.removeEventListener("timeupdate", this.timeline);
      this.$refs.audio.currentTime = event.target.value;
      this.$refs.audio.addEventListener("timeupdate", this.timeline);
    },
    stop() {
      if (this.playing) {
        this.playing = false;
        this.$refs.audio.pause();
      }
    },
    pad(val) {
      val = Math.floor(val);
      if (val < 10) {
        return "0" + val;
      }
      return val + "";
    },
    timeParse(sec) {
      let min = 0;
      min = Math.floor(sec / 60);
      sec = sec - min * 60;
      return this.pad(min) + ":" + this.pad(sec);
    },
    playpause() {
      this.$store.state.playStatus = !this.$store.state.playStatus;
      this.playing = !this.playing;
      if (!this.playing) {
        this.$refs.audio.pause();
      } else {
        this.$refs.audio.play();
        this.$refs.audio.addEventListener("timeupdate", this.timeline);
        this.$refs.audio.addEventListener("ended", () => {
          this.playing = false;
        });
      }
    },
    timeline() {
      this.displayTime =
        this.timeParse(this.$refs.audio.currentTime) +
        " / " +
        this.timeParse(this.$refs.audio.duration);
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-player {
  position: relative;
  width: 100vw;
  height: 30vh;
  padding: calc(6 * var(--lh));
  display: flex;
  z-index:999;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  .controls {
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: var(--lh);
    input[type="range"] {
      -webkit-appearance: none;
      border-top: 1px solid #000;
      height: 0;
      margin: var(--lh) 0;
      width: 50vw;
      outline: none;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        border-left: 1px solid #000;
        height: var(--lh);
        width: 0;
        cursor: pointer;
      }
    }
    div {
      padding: 0 calc(0.5 * var(--lh));
      cursor: pointer;
      transition: color 0.5s;
      &:hover {
        color: #6e6e6e;
      }
    }
  }
  audio {
    max-width: 100%;
    max-height: calc(100% - (2 * var(--lh)));
    outline: none;
  }
}
</style>
